<template>
  <div id="tpl-about" class="template-feature-text-2">
    <b-container>
      <b-row>
        <b-col class="featured-text-col">
          <b-card :title="candidate.featureTitle" title-tag="h3">
            <b-card-text
              class="p-feature-text"
              v-html="candidate.featureText"
            ></b-card-text>
          </b-card>
        </b-col>
        <b-col class="featured-img-col" v-if="candidate.featurePhotoPath">
          <div class="featured-img">
            <b-img
              alt="Featured Profile Image"
              :src="`${candidate.featurePhotoPath}`"
            ></b-img>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Feature Text"
    };
  },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    }
  },
  mounted() {

    let lineBreaks = document.querySelectorAll('.template-feature-text-2 .card .p-feature-text p > br');

    for (let i = 0; i < lineBreaks.length; i++) {

      let breakParent = lineBreaks[i].parentNode;

      breakParent.classList.add('d-none');
      
    }
    
  }
};
</script>

<style lang="scss" scoped></style>
